import React, {useEffect, useState} from "react"
import Layout from "../components/Layout"
import Accordion from "../components/ui/Accordion"
import Block from "../components/ui/Block"
import Button from "../components/ui/Button"
import Card from "../components/ui/Card"
import CTA from "../components/ui/CTA"
import FlexContainer from "../components/ui/FlexContainer"

import { graphql } from "gatsby"
import Filter from "../components/ui/Filter"
export default function Sportarten({data}) {
  const [sports, setSports] = useState([])
  const pageContext = {
    seo: {
      title: "Turnverein Lahr",
      description:
        "Der Turnverein Lahr von 1846 e.V. zählt zu den ältesten Turnvereinen in Baden- Württemberg und ist mit nahezu 1700 Mitgliedern der größte Sport anbietende Verein in Lahr.",
      robots: ["index, follow"],
      path: "/sportarten",
      lang: "de",
    },
  }

  useEffect(() => {
    setSports(data?.allDatoCmsSport.nodes)
  }, [data?.allDatoCmsSport.nodes])
  return (
    <Layout pageContext={pageContext}>
      <Block
        note="Sportarten"
        title="Unser Fitness- und Kursangebot"
        content="Hier findest du alle Sportarten, die wir aktuell anbieten. Wir unterteilen das Angebot in Wettkampf- und Freizeitsport."
      />
      <Filter sports={data?.allDatoCmsSport.nodes} changeSports={setSports}/>
      <FlexContainer gap="24px" className="main-container">
      {sports?.sort((a,b) => a?.title?.localeCompare(b?.title)).map((item) => (
            <Card
            imageUrl={item?.image?.url}
            title={item?.title}
            content={item?.subtitle}
            note={item?.sportart}
            href={`/sportarten/${item?.slug}/`}
          />
          ))}
        </FlexContainer>
        <CTA/>
    </Layout>
  )
}

export const query = graphql`
  query SportQuery {
    allDatoCmsSport {
      nodes {
        image {
          alt
          url
        }
        slug
        sportart
        subtitle
        title
        kategorie
      }
    }
  }
`
