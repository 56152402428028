import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "../../assets/style/components/_flex-container.scss"
import FormGroup from "./FormGroup"
export default function Filter({ sports, changeSports }) {
  const [state, setState] = useState({})

  const handleChange = (category, value) => {
    if (state[category] === value) {
      setState({ ...state, [category]: undefined, search: "" })
    } else {
      setState({ ...state, [category]: value, search: "" })
    }
  }
  const handleSearch = value => {
    setState({ search: value })
  }
  useEffect(() => {
    handleFilter()
  }, [state])

  const handleFilter = () => {
    const newSports = sports.filter(item => {
        // Parse the category JSON safely
        const categories = JSON.parse(item.kategorie || "[]");
        
        // Check if the category matches or if no category is selected
        const categoryMatches = !state?.kategorie || categories.includes(state.kategorie);
        
        // Check if the sport matches or if no sport is selected
        const sportMatches = !state?.sportart || item.sportart === state.sportart;
        
        // Check if the subtitle includes the search term (case-insensitive)
        const searchMatches = !state?.search ||( item.title && item.title.toLowerCase().includes(state.search.toLowerCase())) || (item.subtitle && item.subtitle.toLowerCase().includes(state.search.toLowerCase()));
        
        // Return true if all conditions are met
        return categoryMatches && sportMatches && searchMatches;
    });

    console.log(newSports);
    changeSports(newSports);
}
  return (
    <div>
      <div className="flex-filter row main-container">
        <div className="col-xs-12 col-md-3">
          <FormGroup
            type="select"
            // width="max-content"
            onChange={value => handleChange("sportart", value)}
            placeholder="Alle anzeigen"
            id="filter-all"
            checked={state["sportart"]}
            options={[
              { label: "Fitness & Gesundheit", value: "fitness" },
              { label: "Wettkampf", value: "wettkampf" },
              { label: "Kurse", value: "kurse" },
            ]}
          />
        </div>
        <div className="col-xs-12 col-md-6">
          <FormGroup
            type="search"
            // width="100%"
            onChange={value => handleSearch(value)}
            placeholder="Suche nach z.B. “Gymnastik”"
            id="filter-search"
          />
        </div>
        <div className="col-xs-12 col-md-3">
          <FormGroup
            type="select"
            label="Weitere Filter"
            onChange={value => handleChange("kategorie", value)}
            checked={state["kategorie"]}
            // width="max-content"
            placeholder="Damen- oder Herrensport"
            id="filter-gender"
            options={[
              { label: "Damensport", value: "women" },
              { label: "Herrensport", value: "men" },
              { label: "Kindersport", value: "kids" },
              { label: "Jugendsport", value: "teenager" },
              { label: "Seniorensport", value: "senior" },
              { label: "Mannschaftssport", value: "team" },
            ]}
          />
        </div>
      </div>
    </div>
  )
}
